import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Hero from '../components/Hero'
import HeroVimeoVideo from '../components/HeroVimeoVideo'
import RenderContent from '../components/RenderContent'
import Article, { Title, Preamble } from '../components/Article'

import imageHelper from '../helpers/image-helper'

export default function ArticleTemplate ({ data }) {
  const { title, preamble, heroImage, heroVimeoVideo } = data.ievv
  const getImage = imageHelper(data)
  const hero = heroImage ? getImage(heroImage) : {}
  let bannerContent = null;

  if (heroVimeoVideo != null || heroVimeoVideo != undefined) {
    bannerContent = <HeroVimeoVideo videoId={heroVimeoVideo} title={title} preamble={preamble} />;
  } else if (heroImage) {
    bannerContent = <Hero {...hero} title={title} preamble={preamble} />;
  }

  return (
    <>
      {bannerContent}

      <Article>
        {!heroImage && <Title level="1">{title}</Title>}
        {preamble && !heroImage && <Preamble>{preamble}</Preamble>}

        <RenderContent data={data} />
      </Article>
    </>
  )
}

ArticleTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  darkHeader: PropTypes.bool,
}

ArticleTemplate.defaultProps = {
  darkHeader: true,
}

export const query = graphql`
  query($pageId: String!) {
    ievv(id: { eq: $pageId }) {
      ...ArticleData
    }
    ...GetImages
  }
`
